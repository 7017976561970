@keyframes countUp {
  from {
    counter-reset: count;
  }

  to {
    counter-increment: count 200;
  }
}

.counter {
  counter-reset: count;
  font-size: 24px;
  font-weight: bold;
  color: #008080;
  animation: countUp 5s ease-in-out forwards;
}
