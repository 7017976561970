.rpv-toolbar__right{
    display: none !important;
}

.Search{
    display: none !important;
}

[data-testid="search__popover-button"] {
    display: none;
}